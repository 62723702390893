<template>
  <v-card class="elevation-1">
    <v-card-title>
      13 Month Pay - Assume
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
    <template #[`item.employee_name`]="{ item }">
      {{`${item.lastname}, ${firstname} ${item.middlename}`}}
    </template>
    <template #[`item.actual`]="{ item }">
      {{Intl.NumberFormat().format(((item.regular_pay + item.leave + item.adjustment) - (item.absent + item.late + item.undertime)) / 12)}}
    </template>
    <template #[`item.assume`]="{ item }">
      {{Intl.NumberFormat().format(item.assume)}}
    </template>
    <template #[`item.total`]="{ item }">
      {{Intl.NumberFormat().format((((item.regular_pay + item.leave + item.adjustment) - (item.absent + item.late + item.undertime)) / 12) + item.assume)}}
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import fnx from '@/functions/fn'

export default {
  props: ['formData'],
  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'Employee Number', align: 'start', value: 'employee_number' },
      { text: 'Last Name', value: 'lastname' },
      { text: 'First Name', value: 'firstname' },
      { text: 'Middle Name', value: 'middlename' },
      { text: 'Date Hired', value: 'date_hired' },
      { text: 'Employee Status', value: 'status' },
      { text: 'Employment Status', value: 'employment_status' },
      { text: 'Length of stay', value: 'length_of_stay' },
      { text: 'Actual 13 Month', value: 'actual' },
      { text: 'Assume 13 Month', value: 'assume' },
      { text: 'Total', value: 'total' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const groupBy = ref(null)
    const uri = ref(`${process.env.VUE_APP_URI}/api/payroll/reports/13-month/assume`)

    watch(options, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(() => props.formData, val => {
      uri.value = `${process.env.VUE_APP_URI}/api/payroll/reports/13-month/assume/${fnx.base64_encode(val)}`
      getData(uri.value, items, totalItems, loading, options, search)
      groupBy.value = val.groupBy
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      groupBy,
      fnx,
    }
  },
}
</script>
