var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1"},[_c('v-card-title',[_vm._v(" 13 Month Pay - Assume "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","offset-md":"8","offset-lg":"8"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.searchTable=_vm.search}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.searchTable,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.employee_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.lastname) + ", " + _vm.firstname + " " + (item.middlename)))+" ")]}},{key:"item.actual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(((item.regular_pay + item.leave + item.adjustment) - (item.absent + item.late + item.undertime)) / 12))+" ")]}},{key:"item.assume",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.assume))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Intl.NumberFormat().format((((item.regular_pay + item.leave + item.adjustment) - (item.absent + item.late + item.undertime)) / 12) + item.assume))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }